<template>
    <div class="auth-component-form-input gr-19 push-1 gr-20@small">

        <div>
            <input type="text" id="email" name="email" placeholder="Mailadresse"
                   :value="userData && userData.email? userData.email : ''" @change="saveData">
            <div v-if="!validity.email" class="auth-error">Gib bitte eine gültige Mailadresse an.</div>
        </div>
        <div class="password-field">
            <input :type="passwordType" id="password" name="password" placeholder="Passwort" autocomplete="off"
                    @change="saveData" @focus="setPasswordTypeOnEvent" @blur="setPasswordTypeOnEvent" /><div
                    class="password-symbol" :class="'password-symbol-' + passwordDisplay.login.symbol"
                    @click="setPasswordTypeOnSymbol"></div>
            <div v-if="!validity.password" class="auth-error">Gib bitte ein Passwort ein.</div>
        </div>
    </div>

    <div>
        <div class="auth-component-action gr-19 push-1 gr-20@small">
            <base-button type="submit" class="auth-button button">anmelden</base-button>
            <div class="forgot-password">
                <a @click="switchAuthMode('forgot')">Passwort vergessen?</a>
            </div>
        </div>
        </div>
</template>

<script>
    export default {
        props: ['user', 'validity'],
        emits: ['saveUser', 'switchAuthMode'],
        inject: ['passwordDisplay', 'togglePassword'],
        data() {
            return {
                userData: {
                    email: '',
                    password: ''
                },
                passwordType: 'text',
            }
        },
        watch: {
            user() {
                this.userData.email = this.user.email || '';
                this.$emit('saveUser', this.userData);
            }
        },
        mounted() {
          this.userData.email = this.user.email || '';
          this.$emit('saveUser', this.userData);
        },
        methods: {
            switchAuthMode(mode) {
                this.$emit('switchAuthMode', mode);
            },
            setPasswordTypeOnEvent(event) {
                let type = event.target.type;
                let value = event.target.value;

                if(type === 'blur' && !value) {
                    this.passwordType = 'text'
                } else {
                    this.passwordType = this.passwordDisplay['login'].type;
                }
            },
            async setPasswordTypeOnSymbol() {
                await this.togglePassword('login');
                this.passwordType = this.passwordDisplay['login'].type;
            },
            saveData(event) {
                this.userData[event.target.id] = event.target.value;
                this.$emit('saveUser', this.userData);
            },
        }
    }
</script>


<style scoped>
</style>
